import webDev from '../images/Hero Image.svg';
import webDevMobile from '../mobile_images/Hero-Wrapper__image.svg';
import itExportIcon from '../images/Rectangle 8.png';
import Innovation from '../images/Innovation.png';
import solutions from '../images/solutions.png';
import Vector_1 from '../images/Vector_1.svg';
import Vector_2 from '../images/Vector_2.svg';
import Arrow1 from '../images/Arrow1.svg';
import Arrow2 from '../images/Arrow2.svg';
import Component_1 from '../images/Component_1.svg';
import Flag from '../images/Flag.svg';
import Group_40 from '../images/Group_40.svg';
import Rocket from '../images/Rocket.svg';
import Code_perspective from '../images/Code_perspective.svg';
import Heart_rate from '../images/Heart_rate.svg';
import Shield_perspective from '../images/Shield_perspective_matte.svg';
import Success_perspective from '../images/Success_perspective_matte.svg';
import Padlock_perspective from '../images/Padlock_perspective_matte.svg';
import React, { useState } from 'react';
// import TeckStack from './TeckStack';
import Slider from "./Slider";
// import Experts from "./Experts";
import mobileItExportIcon from '../mobile_images/itexperts.svg';
import mobileInnovation from '../mobile_images/mobileInnovation.svg';
import mobileSolutions from '../mobile_images/mobileSolutions.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


import tech_1 from "../images/back_end.svg";
import tech_2 from "../images/front_end.svg";
import tech_3 from "../images/database.svg";
import tech_4 from "../images/cloud.svg";
import tech_5 from "../images/devops.svg";
import $ from "jquery";


function Home() {
    const {t} = useTranslation();
    const [techStack, setTechStack] = useState('tech_1');

    const techStackChange = (tech_id, event) => {
        var prevTech = document.getElementsByClassName("TechStackSelect");
        var prevLine = document.getElementsByClassName("TechStackLineGradient");

        var tech = document.getElementById(tech_id);
        var lineGradient = document.getElementById("line_"+tech_id);

        // setTechStack(tech_id);

        prevTech[0].className = "TechStackNotSelected";
        prevLine[0].className = "";

        tech.className = "TechStackSelect";
        lineGradient.className = "TechStackLineGradient";


        var tech_stack_img2 = document.getElementsByClassName("TechStackImg2");
        var tech_stack_img_not_display = document.getElementById("image_"+tech_id);

        tech_stack_img2[0].className = "TechStackImgNotDisplay";
        tech_stack_img_not_display.className = "TechStackImg2";

    };


  return (
    <div className="Home">
        <div id="about_us_button" className="Content">
            <div>
                <span className="span-1"> {t("Innovations")} </span>
                <br/>
                <span className="span-2">{t("Across")} </span>
                <span className="span-3">{t("Technologies")}</span>

                <br/><br/>
                <div style={{width: 531}}>
                    <span className="span-4">At </span>
                    <span className="span-5">SOFTURGE</span>
                    <span className="span-4">, we're not just a technology company; we're architects of tomorrow's digital landscape. With a powerhouse of services and a team of top-tier IT experts, we bring your boldest ideas to life.</span>
                </div>

                <Link to="contact_us" className="LetsConnect bttn bttn-primary">
                  <div className="LetsConnectBtn" >Let’s Connect</div>
                </Link>

            </div>
            <div>
                <img style={{width: 748, height: 461}} src={webDev} alt="" />
            </div>
        </div>

        <div id="about_us_button" className="MobileContent">
            <div>
                <div style={{justifyContent:"center",  display:"flex"}}>
                    <span className="span-1"> Unleashing Innovations </span>
                </div>

                <div className="Across">
                    <div>
                        <span className="span-2">Across </span>
                        <span className="span-3">Technologies</span>
                    </div>
                </div>

                <div style={{display: "flex", justifyContent: "center"}}>
                    <img className="webDevMobile" src={webDevMobile} alt="" />
                </div>


                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: '93.333vw'}}>
                        <span className="span-4">At </span>
                        <span className="span-5">SOFTURGE</span>
                        <span className="span-4">, we're not just a technology company; we're architects of tomorrow's digital landscape. With a powerhouse of services and a team of top-tier IT experts, we bring your boldest ideas to life.</span>
                    </div>
                </div>
            </div>

        </div>

        <div className="RoundIconTop">
            <div className="BellowButton"></div>
        </div>


        <div id="our_core_offerings_sliders" className="SliderHomeDiv">
            <Slider/>
        </div>

        <div id="why_choose_us" className="WhyChooseUsDiv">
            <div className="RoundIconBottom">
                <div className="BellowButton"></div>
            </div>

            <div className="WhyChooseDiv">
                <div className="WhyChoose" >Why Choose Us? </div>
            </div>


            <div className="ITExperts ITExpertsMargin">
                <div className="ITExpertsDiv1">
                    <div className="Gradiant"></div>
                    <span className="ITExpertsSpan1">Best </span>
                    <span className="ITExpertsSpan1 ITExpertsSpan2 ">IT Experts</span>

                    <div className="MobileItExportsImgDiv">
                        <img className="ItExportsImg" src={mobileItExportIcon} alt="" />
                    </div>

                    <div className="ITExpertsDiv2">
                        <span className="ITExpertsSpan3">Our </span>
                        <span className="ITExpertsSpan3 ITExpertsSpan4">Team Compromises</span>
                        <span className="ITExpertsSpan3"> the the industry's finest IT experts, passionate about pushing the boundaries of what's possible. With a relentless commitment to excellence, they transform challenges into opportunities.</span>
                    </div>
                </div>
                <div className="ItExportsImgDiv">
                    <img className="ItExportsImg" src={itExportIcon} alt="" />
                </div>

            </div>


            <div className="ITExperts ITExpertsMargin">
                <div className="ItExportsImgDiv">
                    <img src={Innovation} alt="" />
                </div>

                <div className="ITExpertsDiv1">
                    <div className="Gradiant"></div>
                    <span className="ITExpertsSpan1">Innovation At </span>
                    <span className="ITExpertsSpan1 ITExpertsSpan2 ">Every Step</span>

                    <div className="MobileItExportsImgDiv">
                        <img className="ItExportsImg" src={mobileInnovation} alt="" />
                    </div>

                    <div className="ITExpertsDiv2">
                        <span className="ITExpertsSpan3 ITExpertsSpan4">Innovation</span>
                        <span className="ITExpertsSpan3"> is ingrained in our DNA. We don't just keep up with technological trends; we set them. Join us in embracing the future with solutions that redefine the status quo.</span>
                    </div>
                </div>

            </div>

            <div className="RoundGradiant">
                <div className="BellowButton"></div>
            </div>


            <div className="ITExperts ITExpertsMargin">
                <div className="ITExpertsDiv1">
                    <div className="Gradiant"></div>
                    <span className="ITExpertsSpan1">Comprehensive </span>
                    <span className="ITExpertsSpan1 ITExpertsSpan2 ">Solutions</span>

                    <div className="MobileItExportsImgDiv">
                        <img className="ItExportsImg" src={mobileSolutions} alt="" />
                    </div>

                    <div className="ITExpertsDiv2">
                        <span className="ITExpertsSpan3 ITExpertsSpan4">From concept to execution,</span>
                        <span className="ITExpertsSpan3"> we provide end-to-end solutions. Our holistic approach ensures seamless integration across technologies, delivering a unified and efficient digital ecosystem.</span>
                    </div>
                </div>
                <div className="ItExportsImgDiv">
                    <img src={solutions} alt="" />
                </div>

            </div>

            <div className="Vector">
             <div className="Vector1">
                 <div className="Vectordiv2">
                     <img src={Arrow2} alt="" />
                 </div>

                 <img src={Vector_2} alt="" />

             </div>

            <div className="VectorSpanDiv">
                <span className="VectorSpan1">Embark on a transformative journey with </span>
                <span className="VectorSpan2">SOFTURGE</span>
                <div className="VectorSpanDiv1">Your vision, our expertise — together, we build the future.</div>
            </div>

            <div className="Vector2">
                <img src={Vector_1} alt="" />

                <div className="Vectordiv1">
                  <img src={Arrow1} alt="" />
                </div>

            </div>
        </div>

        </div>

        {/*<Experts/>*/}

        <div id="development_approach" className="DevelopmentApproach" >
            <div className="OurExportsDiv">
                <div className="LineGradientSubDiv1"></div>
            </div>

            <div className="OurExportsDiv OurExportsDiv3">
                <div className="OurExportsText OurExportsTextMobile">
                        <span className="OurExportsSpan1" >Our design and <br/></span>
                        <span className="MobileOurExportsSpan1" >Our design and </span>
                        <span className="OurExportsSpan2">development approach </span>
                </div>
            </div>

            <div className="ApproachBox">
                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1">
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Rocket} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">UX Driven Engineering</div>
                            <div className="ApproachDivText2">Unlike other companies, we are a UX first development company. Projects are driven by designers and they make sure design and experiences translate to code.</div>
                        </div>
                    </div>

                </div>

                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1" style={{background: 'linear-gradient(225deg, #68DBF2 0%, #509CF5 100%)'}}>
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Code_perspective} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">Developing Shared Understanding</div>
                            <div className="ApproachDivText2">We facilitating collaborative discussions and knowledge exchange is integral to our approach, fostering a shared understanding among team members for effective and unified development.</div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="ApproachBox">
                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1" style={{background: 'linear-gradient(224deg, #FF92AE 3%, #FF3D9A 100%)'}}>
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Heart_rate} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">Proven Experience and Expertise</div>
                            <div className="ApproachDivText2">Demonstrating a track record of proven experience, our team brings a wealth of expertise to deliver high-impact solutions. we consistently excel in providing top-tier solutions for our clients.</div>
                        </div>
                    </div>

                </div>
                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1" style={{background: 'linear-gradient(225deg, #67E9F1 0%, #24E795 100%)'}}>
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Shield_perspective} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">Security & Intellectual Property (IP)</div>
                            <div className="ApproachDivText2">Our commitment to security extends beyond code, encompassing robust measures to safeguard intellectual property, ensuring comprehensive protection for our clients' assets.</div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="ApproachBox">
                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1" style={{background: 'linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)'}}>
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Success_perspective} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">Code Reviews</div>
                            <div className="ApproachDivText2">Our development process includes rigorous code reviews, ensuring the highest quality, security, and adherence to best practices in every line of code.</div>
                        </div>
                    </div>

                </div>
                <div className="Approach">
                    <div className="ApproachContent">
                        <div className="ApproachDiv1" style={{background: 'linear-gradient(225deg, #F76680 0%, #57007B 100%)'}}>
                            <div className="ApproachIcon">
                                <img className="ApproachIconImg" src={Padlock_perspective} alt=""/>
                            </div>
                        </div>

                        <div className="ApproachDiv2">
                            <div className="ApproachDivText1">Quality Assurance & Testing</div>
                            <div className="ApproachDivText2">Quality assurance is embedded in our development lifecycle, with meticulous testing methodologies that guarantee the delivery of robust and flawless software products.</div>
                        </div>
                    </div>

                </div>
            </div>


        </div>


        <div className="OurApproach" id="tech_stack">
            <div className="OurExportsDiv">
                <div className="LineGradientSubDiv1"></div>
            </div>

            <div className="OurExportsDiv OurExportsDiv3">
                <div className="OurExportsText">
                        <span className="OurExportsSpan1" >Our <br/></span>
                        <span className="OurExportsSpan2Mobile" >Our </span>
                        <span className="OurExportsSpan2">Tech Stack </span>
                </div>
            </div>

            <div className="TechStack">
                <button id="tech_1" className="TechStackSelect" style={{width: '5em'}} onClick={(event) => techStackChange('tech_1', event)}>
                    Backend
                    <div id="line_tech_1" className="TechStackLineGradient"></div>
                </button>

                <button id="tech_2" style={{width: '5em'}} className="TechStackNotSelected" onClick={(event) => techStackChange('tech_2', event)}>
                    Frontend
                    <div id="line_tech_2"></div>
                </button>

                <button id="tech_3" style={{width: '5.5em'}} className="TechStackNotSelected" onClick={(event) => techStackChange('tech_3', event)}>
                    Databases
                    <div id="line_tech_3"></div>
                </button>

                <button id="tech_4" style={{width: '6.7em'}} className="TechStackNotSelected" onClick={(event) => techStackChange('tech_4', event)}>
                    CloudTesting
                    <div id="line_tech_4"></div>
                </button>

                <button id="tech_5" style={{width: '4em'}} className="TechStackNotSelected" onClick={(event) => techStackChange('tech_5', event)}>
                    DevOps
                    <div id="line_tech_5"></div>
                </button>

            </div>

            <div className="TeckStack">
                <div className="TechStackImg">
                    <img id="image_tech_1" className="TechStackImg2" src={tech_1} alt=""/>
                    <img id="image_tech_2" className="TechStackImgNotDisplay" src={tech_2} alt=""/>
                    <img id="image_tech_3" className="TechStackImgNotDisplay" src={tech_3} alt=""/>
                    <img id="image_tech_4" className="TechStackImgNotDisplay" src={tech_4} alt=""/>
                    <img id="image_tech_5" className="TechStackImgNotDisplay" src={tech_5} alt=""/>
                </div>
            </div>

            {/*<TeckStack stackId={ techStack } />*/}
            {/*{techStack}*/}

        </div>


        <div className="Work">
            <div className="OurExportsDiv">
                <div className="LineGradientSubDiv1"></div>
            </div>

            <div className="OurExportsDiv OurExportsDivMobile">
                <div className="OurExportsText">
                        <span className="OurExportsSpan1" >How development  <br/></span>
                        <span className="OurExportsSpan2Mobile" >How development  </span>
                        <span className="OurExportsSpan2">through Alcaline works </span>
                </div>
            </div>

            <div className="AlcalineWorks">
                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#1</div>
                        <div className="AlcalineWorkContainerText">Assemble the right team</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We handle all aspects of vetting and choosing the right team that you don't have the time, expertise, or desire to do.</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#3</div>
                        <div className="AlcalineWorkContainerText">Tech architecture</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#5</div>
                        <div className="AlcalineWorkContainerText">Code reviews</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</div>
                </div>

            </div>

            <div className="MideLineComponent">
                <img src={Flag} alt=""/>
                <img src={Component_1} alt=""/>
            </div>

            <div className="AlcalineWorks AlcalineWorksBottom">
                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#2</div>
                        <div className="AlcalineWorkContainerText">Sprint planning</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#4</div>
                        <div className="AlcalineWorkContainerText">Standups & weekly demos</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#6</div>
                        <div className="AlcalineWorkContainerText">Iterative delivery</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We divide the implementation process into several checkpoints rather than a single deadline.</div>
                </div>
            </div>


            <div className="MobileAlcalineWorks">
                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#1</div>
                        <div className="AlcalineWorkContainerText">Assemble the right team</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We handle all aspects of vetting and choosing the right team that you don't have the time, expertise, or desire to do.</div>
                </div>


                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#2</div>
                        <div className="AlcalineWorkContainerText">Sprint planning</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.</div>
                </div>


                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#3</div>
                        <div className="AlcalineWorkContainerText">Tech architecture</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently</div>
                </div>


                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#4</div>
                        <div className="AlcalineWorkContainerText">Standups & weekly demos</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#5</div>
                        <div className="AlcalineWorkContainerText">Code reviews</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells</div>
                </div>

                <div className="AlcalineWorkContainer">
                    <div className="AlcalineWorkContainerDiv">
                        <div className="GradiantTextColor">#6</div>
                        <div className="AlcalineWorkContainerText">Iterative delivery</div>
                    </div>
                        <div className="AlcalineWorkContainerText2">We divide the implementation process into several checkpoints rather than a single deadline.</div>
                </div>

            </div>

        </div>


        <div id="connect_with_us" className="ConnectWithUs">
                <div className="ConnectWithUsDiv1">
                    <div className="ConnectWithUsText">Connect with Softurge</div>
                    <div className="ConnectWithUsDiv2">
                        <div className="ConnectWithUsImgDiv">
                           <img className="ConnectWithUsImg" src={Group_40} alt="" />
                        </div>

                        <Link to="contact_us" className="ConnectWithUsButton bttn bttn-primary">
                            <span className="ConnectWithUsButtonText">Contact With Us</span>
                        </Link>

                    </div>

                </div>

        </div>

    </div>
  );
}

export default Home;
