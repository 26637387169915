import './main.css';
import './mobile_main.css';
import logo from '../images/softurge_logo_black.svg';
import { Outlet, Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import $ from 'jquery';
import menu from '../mobile_images/lucide_menu.svg';
import mobile_logo from '../images/softurge_logo_black.svg';
import close_icon from '../mobile_images/close.png';


function Header() {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const currentPage = location.pathname;

    var $li = $('nav li').click(function() {
        $li.removeClass('ElementSelected');
        $(this).addClass('ElementSelected');
    });

    function changeLanguageHandler(lang)
     {
       i18n.changeLanguage(lang);
     }


  function ScrollPage (div_id){
        const targetElement = document.getElementById(div_id);

        if (currentPage === "/contact_us") {
            navigate("/");
        }

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth',
          });
        }
  }

  function contactUS () {
      navigate("/contact_us");
  }

  function OpenMobileMenu(){
        const targetElement = document.getElementById('sidenav');
        targetElement.style.display = 'block';
  }

  function CloseMobileMenu(){
        const targetElement = document.getElementById('sidenav');
        targetElement.style.display = 'none';
  }


  return (
      <div className="NavBar">
          {/*<Cursor/>*/}
          <nav className="pc-view">
              <div className="softurge_logo" onClick={event => navigate('/')}/>

    <ul className="Menu">
        <li id="home" className="ElementSelected" onClick={() => ScrollPage('about_us_button')} >{t("key")}</li>
          <li id="service" className="" style={{width: '4em'}} onClick={() => ScrollPage('our_core_offerings_sliders')} >Services</li>
          <li className="" style={{width: '7.5em'}} onClick={() => ScrollPage('why_choose_us')} >Why Choose us</li>
          <li className="" style={{width: '7em'}} onClick={() => ScrollPage('connect_with_us')} >Company Info</li>
          <li >
              <div className="dropdown" style={{width: '6rem'}}>
                  <button className="dropbtn">Language</button>
                  <div className="dropdown-content">
                    <button onClick={() => changeLanguageHandler("en")}>English</button>
                    {/*<button onClick={() => changeLanguageHandler("ko")}>Korean</button>*/}
                  </div>
              </div>
          </li>
          </ul>

          <div className="btn-div">
              <button id="contact_btn_id" className="btn bttn bttn-primary" onClick={contactUS}>
                  <div style={{fontSize: '0.875rem', fontWeight: '600', lineHeight: '0.875rem', wordWrap: 'break-word'}}>Contact us</div>
              </button>
          </div>

      </nav>

      <div className="mobile-view">
          <div id="sidenav" className="sidenav">
              <button className="CloseButton">
                  <img onClick={CloseMobileMenu} className="CloseIcon" src={close_icon} alt="close_icon"/>
              </button>
              <button onClick={() => ScrollPage('about_us_button')} >{t("key")}</button>
              <button onClick={() => ScrollPage('our_core_offerings_sliders')} >Services</button>
              <button onClick={() => ScrollPage('why_choose_us')} >Why Choose us</button>
              <button onClick={() => ScrollPage('connect_with_us')} >Company Info</button>
              <button onClick={contactUS} >Contact us</button>
          </div>

          <button onClick={OpenMobileMenu}>
              <img className="menu-logo" src={menu} alt="" />
          </button>

          <Link to="/"><img className="mobile-logo" src={mobile_logo} alt=""/></Link>

      </div>

        <Outlet />

    </div>
  );
}

export default Header;
